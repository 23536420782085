<template>
  <a-form
      :model="model"
      name="basic"
      :label-col="{ span: 0 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :rules="rules"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
  >
    <template v-if="!sendSuccess">
      <div class="t-form__inputsbox">
        <div class="t-input-group t-input-group_nm" data-input-lid="1495810359387"
        >
          <div
              class="t-input-block"
              style="border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;"
          >
            <a-form-item name="name">
              <a-input
                  autocomplete="name"
                  class="t-input js-tilda-rule "
                  v-model:value="model.name"
                  placeholder="Имя"
                  style="color:#353534;border:1px solid #c9c9c9;border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;"
              />
            </a-form-item>
          </div>
        </div>
        <div class="t-input-group t-input-group_em" data-input-lid="1495810354468"
        >
          <div
              class="t-input-block"
              style="border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;"
          >
            <a-form-item name="email">
              <a-input
                  autocomplete="email"
                  class="t-input js-tilda-rule "
                  v-model:value="model.email"
                  placeholder="Email"
                  style="color:#353534;border:1px solid #c9c9c9;border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;"
              />
            </a-form-item>
          </div>
        </div>
        <div class="t-input-group t-input-group_ph" data-input-lid="1495810410810"
        >
          <div
              class="t-input-block"
              style="border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;"
          >
            <a-form-item name="tel"
                         :rules="[{ required: true, message: 'Пожалуйста введите телефон!' }]"
            >
              <a-input
                  autocomplete="tel"
                  v-model:value="model.tel"
                  class="t-input js-tilda-rule js-tilda-mask "
                  placeholder="Телефон"
                  style="color:#353534;border:1px solid #c9c9c9;border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;"
              />
            </a-form-item>
          </div>
        </div>
        <div v-if="!hideTextarea" class="t-input-group t-input-group_ta" data-input-lid="1660915920589">
          <div
              class="t-input-block"
              style="border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;"
          >
            <a-form-item
                name="text"
                :rules="[{ required: true, message: 'Пожалуйста введите сообщение!' }]"
            >
              <a-textarea
                  v-model:value="model.text"
                  class="t-input js-tilda-rule "
                  placeholder="Сообщение"
                  style="color:#353534; border:1px solid #c9c9c9; border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;height:102px;"
                  rows="3"
              />
            </a-form-item>
          </div>
        </div>
        <div class="t-form__submit">
          <button
              type="submit"
              class="t-submit"
              style="color:#ffffff;background-color:#0092be;border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px;font-family:TTHoves;font-weight:500;"
          >
            Отправить
          </button>
        </div>
      </div>
    </template>
    <a-result
        v-if="sendSuccess"
        status="success"
        title="Данные успешно отправлены! Мы свяжемся с вами в ближайшее время."
    />
    <a-result
        v-if="sendError"
        status="warning"
        title="Произошла ошибка, проверьте заполненные поля формы и попробуйте еще раз."
    />

    <div class="t702__form-bottom-text t-text t-text_xs t-align_center"><span
        style="font-family: TTHoves; font-weight: 300;">Нажимая кнопку вы соглашаетесь на </span><a
        href="https://www.all-billboards.ru/registration/agreement"
        style="font-family: TTHoves; font-weight: 300; color: rgb(0, 146, 190); border-bottom: 1px solid rgb(0, 146, 190); box-shadow: none; text-decoration: none;"
        target="_blank" rel="noreferrer noopener">обработку персональных данных</a></div>
  </a-form>
</template>

<script>
import api from '/src/axios';

const checkEmail = async (_rule, value) => {
  const regExp = /^(?!\.)(?!.*\.\.)[a-zA-Zёа-яЁА-Я0-9\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD_\.\-\+]{0,63}[a-zA-Zёа-яЁА-Я0-9\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD_\-\+]@[a-zA-Zёа-яЁА-ЯЁёäöüÄÖÜßèéû0-9][a-zA-Zёа-яЁА-ЯЁёäöüÄÖÜßèéû0-9\.\-]{0,253}\.[a-zA-Zёа-яЁА-Я]{2,10}$/gi;
  if (!value) {
    return Promise.reject('Пожалуйста введите email');
  }
  if (value.length && !value.match(regExp)) {
    return Promise.reject('Пожалуйста укажите корректный email');
  }
};

const defaultModel = () => ({
  name: '',
  email: '',
  tel: '',
  text: '',
});

export default {
  name: 'FormComponent',
  props: [ 'name', 'hideTextarea' ],
  created() {
  },
  data: function () {
    return {
      model: defaultModel(),
      sendSuccess: false,
      sendError: false,
      rules: {
        name: { required: true, message: 'Пожалуйста введите имя!' },
        email: { required: true, validator: checkEmail, trigger: 'change' },
        tel: { required: true, message: 'Пожалуйста введите телефон!' }
      }

    }
  },
  methods: {
    onFinishFailed(errorInfo) {
      console.log('Failed:', errorInfo);
    },

    onFinish(values) {
      api.post('/landings/dsp/send', { ...values, formName: this.name })
          .then(() => {
            this.sendSuccess = true;
            this.model = defaultModel();
          })
          .catch(() => {
            this.sendError = true;
          })
    }
  }
}
</script>